import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 564.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,564.000000) scale(0.100000,-0.100000)">
          <path d="M3740 3338 c-131 -15 -224 -60 -317 -154 -250 -253 -177 -688 141
-839 215 -101 438 -65 602 99 81 81 125 162 149 275 22 108 20 128 -19 167
-41 41 -82 45 -123 10 -25 -21 -30 -36 -40 -104 -14 -96 -46 -162 -105 -219
-66 -65 -126 -88 -228 -88 -73 0 -93 4 -142 28 -72 35 -130 94 -164 165 -40
83 -40 193 -1 277 51 109 172 195 275 195 56 0 110 18 127 43 23 33 19 81 -10
115 -29 35 -56 40 -145 30z"/>
          <path d="M6440 3008 c0 -161 5 -311 10 -332 23 -102 129 -164 213 -125 17 8
23 17 20 30 -4 15 -15 19 -52 19 -99 0 -121 44 -121 240 l0 140 65 0 c63 0 65
1 65 25 0 24 -2 25 -65 25 l-65 0 -1 113 c-1 61 0 122 1 135 1 19 -3 22 -34
22 l-36 0 0 -292z"/>
          <path d="M7460 2915 l0 -375 35 0 35 0 0 375 0 375 -35 0 -35 0 0 -375z" />
          <path d="M4064 3204 c-21 -10 -37 -29 -49 -57 -48 -119 -69 -152 -115 -174
-26 -14 -63 -23 -88 -23 -151 0 -209 -170 -86 -251 85 -56 204 20 204 129 0
91 42 139 162 187 81 32 103 54 103 106 0 69 -69 113 -131 83z"/>
          <path d="M4717 3096 c-84 -30 -131 -80 -168 -175 -54 -143 19 -309 159 -361
118 -45 252 -10 324 85 95 126 80 286 -38 394 -75 68 -182 90 -277 57z m189
-75 c50 -23 91 -74 110 -138 18 -58 11 -111 -22 -173 -66 -122 -241 -145 -335
-43 -81 87 -87 188 -18 289 54 79 171 107 265 65z"/>
          <path d="M6968 3094 c-65 -23 -126 -78 -154 -138 -19 -40 -24 -68 -24 -131 0
-161 88 -264 240 -282 105 -12 234 48 275 128 15 28 14 29 -15 44 -17 9 -30
11 -30 5 0 -24 -73 -89 -117 -104 -142 -48 -266 22 -289 163 l-7 41 248 0 247
0 -7 47 c-13 90 -74 176 -149 213 -59 28 -158 35 -218 14z m170 -60 c61 -25
106 -76 126 -141 l7 -23 -207 0 -206 0 6 32 c8 43 53 97 102 125 47 27 118 30
172 7z"/>
          <path d="M5363 3070 c-78 -39 -107 -74 -133 -157 -18 -58 -20 -92 -20 -319 l0
-254 35 0 35 0 0 151 0 150 43 -34 c59 -49 109 -67 182 -67 114 0 203 59 252
167 21 46 25 66 21 133 -3 72 -6 83 -43 136 -24 36 -59 70 -89 89 -43 26 -60
30 -135 33 -80 3 -91 1 -148 -28z m259 -67 c172 -119 86 -403 -122 -403 -130
0 -220 92 -220 225 0 74 25 133 72 168 51 40 77 47 153 44 60 -2 76 -7 117
-34z"/>
          <path d="M5884 3058 c7 -18 57 -130 110 -249 l96 -217 -50 -116 c-28 -64 -50
-119 -50 -121 0 -3 13 -5 30 -5 36 0 31 -9 170 310 56 129 110 253 120 275 10
22 30 66 44 98 l25 57 -33 0 -33 0 -89 -205 c-49 -112 -91 -205 -94 -205 -3 0
-29 53 -57 118 -29 64 -70 156 -92 205 -40 85 -42 87 -76 87 l-35 0 14 -32z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
